.link{
    margin: 2rem;
   
}
#box{
   animation: slide .5s  forwards;
    
}


@keyframes slide {
    0%{
        transform: translate(700px,0px);
    }
    100%{
        transform: translate(0px,0px);
    }
}


