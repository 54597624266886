#projects {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: fit-content;
    margin: auto;
}

#cards {
    display: flex;
    margin-top: 3rem;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 20rem;
    height: 30rem;
    background: linear-gradient(to right, #F9BE08, #EFD30B, #FFE10C);
    border-radius: 1rem;
    margin-bottom: 3rem;

    &__image,
    &__desc {
        height: 30%;
        width: 80%;
        border-top-right-radius: 1rem;
        border-top-left-radius: 1rem;
    }

    &__image {
        display: flex;
        align-items: center;
        height: auto;
        justify-content: center;
    }
}

.card:hover {
    animation: focus 1s forwards;
}


@keyframes focus {
    0% {}

    100% {
        transform: translate3D(0, -1px, 0) scale(1.03);
    }
}