.tile{
    width: 150px;
    height: 150px;
    border: 3px solid #F9BE08;
    border-radius: 3px;
    margin: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.data{
    text-decoration: underline #F9BE08;
    
}
#skills{
    margin-left: 4rem;
    margin-right: 4rem;
}

#mobileskills{
    margin-left: 1rem;
    margin-right: 1rem;
}